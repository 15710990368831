/* Cards */
.card-columns .card {
    margin-bottom: .75rem;
    margin-left: .75rem;
    margin-right: .75rem;
  }
  
  .card-img, .card-img-top {
    width: 100%;
    height: 10rem;
  }
  
  .card-title {
    margin: 0;
  }
  
  div .card-body {
    height: 5rem;
    background-color: black;
    padding: 1rem;
  }
  
  p .char-description {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
  
  .h5 {
    font-size: 1rem;
  }
  
  div .card-columns {
    column-count: 4;
    padding-top: 2rem;
  }

  /* Popover */
  .popover-body {
    background-color: black;
    color: white;
  }

  div .char-description {
    font-size: 10px;
    font-weight: bold;
  }
